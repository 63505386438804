.contain {
  background-color: #f9f9f9;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  /* background-image:url("../../../image/login_icon.jpg"); */
  background-color:whitesmoke;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  flex-direction: column;
}
.form_contain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
  min-width: 800px;
  margin-bottom: 20px;

}
.title_name {
  color: #cc0000;
  font-size: 64px;
  margin-bottom: 50px;
}
.form_title {
  color: #ffffff;
  margin-top: 50px;
  padding-bottom: 50px;
}
.form {
  width: 45%;
}
.form_control {
  font-size: 24px;
}
