body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.confirm-button-ok {
  background-color: var(--bs-primary) !important;
  color: white;
}

.Action {
  visibility: hidden;
}

.tbody:hover .Action {
  visibility: visible;
}

.nx-confirm-button-ok {
  background-color: var(--bs-primary) !important;
  color: white;
}

h1,
h2,
h3,
h4,
h5 {
  color: #434c5e !important;
}

.breadcrumb-item,
.doc-labels {
  cursor: pointer;
  font-size: 16px;
}

table tr th {
  background-color: var(--bs-primary) !important;
  color: #ffff !important;
}

#reportrange,
.t-header {
  font-size: 18px !important;
  font-weight: bold;
  color: var(--bs-primary) !important;
}

.text-wrap {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  padding-left: 5px !important;
}

.text-indent {
  text-indent: 30px;
}

.limit-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.select2-search__field {
  padding: 8px !important;
  width: 100% !important;
}

li,
a,
p,
span,
input,
label,
select,
button,
table,
th,
td,
select.form-control,
.search-query,
.menu-text,
.page-header,
textarea,
.dataTables_info {
  font-size: 1rem !important;
  font-weight: normal !important;
}

.center_content {
  display: flex;
  justify-content: center;
}

.pageNumbers {
  list-style: none;
  display: flex;
  padding-left: 14px;
}

.pageNumbers li {
  padding: 8px;
  border: 0.1px solid #eee;
  cursor: pointer;
}

.pageNumbers li.active {
  background-color: var(--bs-primary);
  color: #fff;
}

.pageNumbers li button {
  cursor: pointer;
}

.pageNumbers li button:hover {
  background-color: var(--bs-primary);
  color: black;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000 !important;
  opacity: 0.2 !important;
  /* Firefox */
}

/* Invalid Text */
.invalid-feedback {
  font-size: small !important;
  font-style: italic;
}

/* Zoom image */
.img-zoom-hover:hover {
  transform: scale(5);
}

.link-hover:hover {
  color: var(--bs-primary);
}
 
/*\
 * Restore Bootstrap 3 "hidden" utility classes.
\*/

/* Breakpoint XS */
@media (max-width: 575px)
{
    .hidden-xs-down, .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down, 
    .hidden-xs-up, 
    .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl
    {
        display: none !important;
    }

}

/* Breakpoint SM */
@media (min-width: 576px) and (max-width: 767px)
{
    .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down, 
    .hidden-xs-up, .hidden-sm-up, 
    .hidden-unless-xs, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl
    {
        display: none !important;
    } 
}

/* Breakpoint MD */
@media (min-width: 768px) and (max-width: 991px)
{
    .hidden-md-down, .hidden-lg-down, .hidden-xl-down, 
    .hidden-xs-up, .hidden-sm-up, .hidden-md-up, 
    .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-lg, .hidden-unless-xl
    {
        display: none !important;
    } 
}

/* Breakpoint LG */
@media (min-width: 992px) and (max-width: 1199px)
{
    .hidden-lg-down, .hidden-xl-down, 
    .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up, 
    .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-xl
    {
        display: none !important;
    } 
}

/* Breakpoint XL */
@media (min-width: 1200px)
{
    .hidden-xl-down, 
    .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up, .hidden-xl-up, 
    .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg
    {
        display: none !important;
    } 
}
.hvr-shutter-out-horizontal {
 
  color: white important;
}